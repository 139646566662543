import React, { useState, useEffect  } from 'react';
import { TextField, Grid ,Avatar, Modal,Typography, IconButton ,Button, Divider, InputBase, Paper} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import UndoIcon from '@mui/icons-material/Undo';
import EditIcon from '@mui/icons-material/Edit';
import { EliminarUsuario } from '../utils/apiServer';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar } from 'swiper/modules';
import SearchIcon from '@mui/icons-material/Search';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import 'swiper/css';
import 'swiper/css/scrollbar';
import '../assets/css/carrusel.css'


function capitalize(value) {
  if (typeof value !== 'string') {
    // console.error("Expected a string in capitalize function, but got:", typeof value);
    return '';
  }

  // Divide la cadena en palabras
  let words = value.toLowerCase().split(' ');

  // Capitaliza la primera letra de cada palabra
  let capitalizedWords = words.map(word => {
    // Capitaliza la primera letra de la palabra
    let capitalized = word.charAt(0).toUpperCase() + word.slice(1);
    return capitalized;
  });

  // Une las palabras nuevamente en una cadena
  let capitalized = capitalizedWords.join(' ');

  // Si la cadena comienza con 'Los', agrega un espacio después de 'Los'
  if (capitalized.startsWith('Los')) {
    capitalized = capitalized.replace('Los', 'Los ');
  }

  // Si la cadena comienza con 'Puerto', agrega un espacio después de 'Puerto'
  if (capitalized.startsWith('Puerto')) {
    capitalized = capitalized.replace('Puerto', 'Puerto ');
  }

  return capitalized;
}

const Usuario = ({ usuarios,translate, initialUser, setEditar, editar, setUser, actualizarListaUsuarios, usuarioSeleccionado, setUsuarioSeleccionado }) => {
  const [openModal, setOpenModal] = useState(false); // si se decea eliminar usuario
  const [openData, setOpenData] = useState(false); // si se decea eliminar usuario
  const [usuariosActualizados, setUsuariosActualizados] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  useEffect(() => {
    // Aquí puedes realizar cualquier acción necesaria cuando la lista de usuarios cambie
    if(usuarios != usuariosActualizados){
      setUsuariosActualizados(usuarios);
    }
  }, [usuarios, editar]);

  
  // Función para manejar el clic en el usuario
  const handleClickUsuario = (usuario) => {
    setUsuarioSeleccionado(usuario);
  };

  // Función para abrir el modal de confirmación de eliminación
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  // Función para cerrar el modal
  const handleCloseModal = () =>{
    setOpenModal(false);
  }

   // Función para abrir el modal de confirmación de eliminación
   const handleOpenData = () => {
    setOpenData(true);
  };

    // Función para cerrar el modal
    const handleCloseData = () =>{
      setOpenData(false);
    }

  const handleCloseModalDelete = () => {
    let clientName = localStorage.getItem('username');
    console.log('clientName',clientName)
    // Verificar si hay un usuario seleccionado antes de eliminarlo
    if (usuarioSeleccionado) {
      console.log("Usuario seleccionado:", usuarioSeleccionado.username);
      // Llamar a la función para eliminar el usuario seleccionado
      EliminarUsuario(clientName, usuarioSeleccionado.username)
        .then(async () => {
          // Una vez eliminado el usuario, cerrar el modal
          setOpenModal(false);
          // Opcionalmente, puedes actualizar la lista de usuarios aquí si es necesario
          // Actualiza la lista de usuarios después de eliminar el usuario
          await actualizarListaUsuarios();
        })
        .catch((error) => {
          // Manejar cualquier error que ocurra durante la eliminación del usuario
          console.error("Error al eliminar usuario:", error);
          // Opcionalmente, puedes mostrar un mensaje de error al usuario
        });
    } else {
      console.warn("No se ha seleccionado ningún usuario para eliminar.");
      // Opcionalmente, puedes mostrar un mensaje al usuario indicando que no ha seleccionado ningún usuario
      setOpenModal(false);
    }
  };

  const handleEditUser = (user) => {
    setEditar(true);
    setOpenData(false);
    localStorage.setItem('usuario',usuarioSeleccionado.username)
    setUser(usuarioSeleccionado); // Establece los datos del usuario seleccionado en el formulario
  };
  const cancelarEdicion = () =>{
    setEditar(false);
  }

  const generarColorAvatar = (username) => {
    // Generar un valor hash a partir del nombre de usuario
    let hash = 0;
    for (let i = 0; i < username.length; i++) {
      hash = username.charCodeAt(i) + ((hash << 5) - hash);
    }
    // Convertir el hash a un color hexadecimal
    const color = (hash & 0x00FFFFFF).toString(16).toUpperCase();
    // Añadir ceros a la izquierda si es necesario
    return "#" + "00000".substring(0, 6 - color.length) + color;
  };

  const filteredUsuarios = usuariosActualizados.filter((usuario) =>
    usuario.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Grid container justifyContent='center' style={{backgroundColor:'rgb(0,0,0,0.4)', marginTop:'5vh', height:'35.5vh'}}>
      <Grid container justifyContent='center' >
        <h1 style={{fontSize:'3vh'}}>{translate('list_user')}</h1>
      </Grid>
       <Grid container justifyContent='center' style={{ height:'5.5vh', marginTop:'-6vh'}}>
        <Paper 
          component="form"
          style={{ alignItems: 'center', width: '15vw', height:'5vh' }}>
            <Grid container justifyContent='center' >
              <InputBase
                fullWidth
                style={{marginLeft:'1vw', marginTop:'0.9vh', fontSize:'1.8vh'}}
                placeholder={translate('buscar_user')}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Divider sx={{ height: '3.5vh',marginLeft:'12vw', marginTop:'-3.7vh' }} orientation="vertical" />
              {/* <IconButton type="button" sx={{ p: '10px',marginTop:'-5.8vh',  }} aria-label="search"> */}
                <SearchIcon style={{fontSize:'2vh',marginTop:'-2.8vh',marginLeft:'0.5vw', zIndex:0}} />
              {/* </IconButton> */}
            </Grid>
          </Paper>
       </Grid>
      <Grid container justifyContent='center'  minWidth={'80vw'} style={{ marginTop:'-5vh', height:'15vh'}}>
    
        <Swiper
          spaceBetween={10}
          slidesPerView={10} // cantidad de ususraios visibles
          scrollbar={{ hide: true }}
          modules={[Scrollbar]}
          // className="mySwiper"
          style={{ width:'90%'}}
        >
          {filteredUsuarios.map((usuario, index) => (
            <SwiperSlide key={index} >
              <Grid marginTop='-1vh' container justifyContent='center'  key={index} onDoubleClick={() => handleOpenData(usuario)}  onClick={() => handleClickUsuario(usuario)} style={{ backgroundColor:usuarioSeleccionado === usuario && '#5f6b76'  , marginLeft:'1vw', borderRadius:'1vh',width:'5vw' }}>
                <Grid container justifyContent='center' alignItems='center' style={{ marginTop:'2vh', position: 'relative'  }}>
                  <Avatar  variant="soft" sx={{ bgcolor: generarColorAvatar(usuario.username), fontSize:'2.5vh', width:'5vh', height:'5vh' }}>{usuario.username.charAt(0)}</Avatar>
                  {/* IconButton para el ícono de eliminación */}
                  {usuarioSeleccionado === usuario && (
                    <>
                      <IconButton onClick={handleOpenModal} sx={{ position: 'absolute', top: -25, right: -7, }} >
                        <DeleteIcon style={{fontSize:'2.2vh'}} />
                      </IconButton>
                      { editar === true ?
                        <IconButton onClick={cancelarEdicion} sx={{ position: 'absolute', top: -25, left: -6, }} >
                          <UndoIcon style={{fontSize:'2.2vh'}} />
                        </IconButton>
                        : ''
                      }
                      { editar === false ?
                        <IconButton onClick={() => handleOpenData(usuario)}  sx={{ position: 'absolute', top: -25, left: -6, }} >
                          <OpenInFullIcon style={{fontSize:'2.2vh'}} />
                        </IconButton>
                        : ''
                      }
                    </>
                  )}
                </Grid>
                <Grid container justifyContent='center' style={{marginTop:'-1vh'}}>
                  {/* Manejar el clic en el usuario */}
                  <p style={{fontSize:'2vh'}}>{usuario.username}</p>
                </Grid>
              </Grid>
            </SwiperSlide>
          ))}
        </Swiper>
      </Grid>

       {/* Modal de confirmación de eliminación */}
       <Modal open={openModal} onClose={handleCloseModal}>
        <Grid  container justifyContent='center' style={{ height:'13vh', width:'22vw', borderRadius:'2vh', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#1e1e1e', padding: 20 }}>
          <Typography variant="h6" style={{fontSize:'2vh'}}>{translate('confirm_delete_user')}</Typography>
          <Grid container justifyContent='center' spacing={2}>
            <Grid item> 
              <Button style={{width:'5vw', height:'4vh', fontSize:'1.8vh'}} variant='outlined' color='inherit' onClick={() => {
                // Aquí puedes implementar la lógica para eliminar el usuario
                console.log("Usuario eliminado:", usuarioSeleccionado);
                handleCloseModalDelete(usuarioSeleccionado);
              }}>{translate('si')}</Button>
            </Grid>
            <Grid item>
              <Button style={{width:'5vw', height:'4vh', fontSize:'1.8vh'}} variant='outlined' color='inherit' onClick={handleCloseModal}>{translate('no')}</Button>
            </Grid>
          </Grid>
        </Grid>
      </Modal>

         {/* Modal Data */}
         <Modal open={openData}  style={{ cursor: 'default !important', userSelect: 'none',  }}>
          <Grid  container justifyContent='center' style={{  height:'60vh', width:'50vw', borderRadius:'2vh', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#1e1e1e', padding: 20 }}>
            <Grid container justifyContent='center' height='5vh'>
              <h1 variant="h6" style={{ fontSize: '2vh', marginTop:'0.5vh' }}>{translate('data_user')}</h1>
            </Grid>
            <IconButton onClick={handleEditUser}  sx={{ position: 'absolute', top: 5, right: 8, }} >
              <EditIcon style={{fontSize:'2.2vh'}} />
            </IconButton>
            <Grid container justifyContent='center'  height='40vh' style={{}}>
              {/* {usuarioSeleccionado && (
                <Grid container justifyContent='center' height='5vh' style={{}}>
                  <Grid container justifyContent='center' height='5vh'>
                    <Grid container justifyContent='center' border='solid 0.01vh' height='5vh' fontSize='2vh' alignContent='center' item xs={2}>{translate('username')}</Grid>
                    <Grid container justifyContent='center' border='solid 0.01vh' height='5vh' fontSize='2vh' alignContent='center' item xs={3}>{translate('email')}</Grid>
                    <Grid container justifyContent='center' border='solid 0.01vh' height='5vh' fontSize='2vh' alignContent='center' item xs={2}>{translate('cargo')}</Grid>
                    <Grid container justifyContent='center' border='solid 0.01vh' height='5vh' fontSize='2vh' alignContent='center' item xs={5}>{translate('permisos')}</Grid>
                  </Grid>
                  <Grid container justifyContent='center'  height='5vh'>
                    <Grid container justifyContent='center' border='solid 0.1vh' height='5vh' fontSize='1.5vh' alignContent='center' item xs={2}>{usuarioSeleccionado.username}</Grid>
                    <Grid container justifyContent='center' border='solid 0.1vh' height='5vh' fontSize='1.5vh' alignContent='center' item xs={3}>{usuarioSeleccionado.correo}</Grid>
                    <Grid container justifyContent='center' border='solid 0.1vh' height='5vh' fontSize='1.5vh' alignContent='center' item xs={2}>{usuarioSeleccionado.cargo}</Grid>
                    <Grid container justifyContent='center' border='solid 0.1vh' height='5vh' fontSize='1.5vh' alignContent='center' item xs={5}>
                       {usuarioSeleccionado.permisos.map((region, regionIndex) => (
                        region.AREAS.map((area, areaIndex) => (
                          area.CENTRO.map((centro, centroIndex) => (
                            <Grid container justifyContent='center' key={`${regionIndex}-${areaIndex}-${centroIndex}`}>
                              {centroIndex === 0 && (
                                <Grid item xs={4}style={{ fontSize: '1.7vh', textAlign: 'center' }}>
                                  {region.REGION}
                                </Grid>
                              )}
                              {centroIndex === 0 && (
                                <Grid item xs={4} style={{ fontSize: '1.7vh', textAlign: 'center' }}>
                                  {area.AREA}
                                </Grid>
                              )}
                              <Grid item xs={4} style={{ fontSize: '1.7vh', textAlign: 'center', color:'green' }}>{centro}</Grid>
                            </Grid>
                          ))
                        ))
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              )} */}
              {usuarioSeleccionado && (
                <>
                  <Grid container justifyContent='center' style={{ height: '5vh', marginTop:'2vh' }}>
                    <h3 style={{ fontSize: '2.5vh', marginTop: '0.1vh' }}>{translate('user')}</h3>
                  </Grid>
                  <Grid container justifyContent='center' style={{ height: '8vh' }}>
                    <Grid container justifyContent='center'>
                      <Grid item xs={4} style={{ fontSize: '2vh', textAlign: 'center',  border: 'solid 0.1vh #515151', height:'4vh' }}>{translate('username')}</Grid>
                      <Grid item xs={4} style={{ fontSize: '2vh', textAlign: 'center',  border: 'solid 0.1vh #515151', height:'4vh' }}>{translate('email')}</Grid>
                      <Grid item xs={4} style={{ fontSize: '2vh', textAlign: 'center',  border: 'solid 0.1vh #515151', height:'4vh' }}>{translate('cargo')}</Grid>
                    </Grid>
                    <Grid container justifyContent='center'>
                      <Grid item xs={4} style={{ fontSize: '1.5vh', textAlign: 'center',  border: 'solid 0.1vh #515151', height:'4vh' }}>{usuarioSeleccionado.username}</Grid>
                      <Grid item xs={4} style={{ fontSize: '1.5vh', textAlign: 'center',  border: 'solid 0.1vh #515151', height:'4vh' }}>{usuarioSeleccionado.correo}</Grid>
                      <Grid item xs={4} style={{ fontSize: '1.5vh', textAlign: 'center',  border: 'solid 0.1vh #515151', height:'4vh' }}>{usuarioSeleccionado.cargo}</Grid>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent='center' style={{ height: '5vh', marginTop:'1vh' }} >
                    <h3 style={{ fontSize: '2.5vh', marginTop: '0.1vh' }}>{translate('permisos')}</h3>
                  </Grid>
                  <Grid container justifyContent='center' style={{ /*border: 'solid 0.1vh #515151',*/ height: '25vh' }}>
                      <Grid container justifyContent='center' alignContent='center' xs={4} border=' solid 0.1vh #515151' style={{fontSize:'2vh', height:'4vh'}}>
                        {translate('region')}
                      </Grid>
                      <Grid container justifyContent='center'  alignContent='center' xs={4} border=' solid 0.1vh #515151' style={{fontSize:'2vh', height:'4vh'}}>
                        {translate('area')}
                      </Grid>
                      <Grid container justifyContent='center'  alignContent='center' xs={4} border=' solid 0.1vh #515151' style={{fontSize:'2vh', height:'4vh'}}>
                        {translate('centro')}
                      </Grid>
                      <Grid  container justifyContent='center' style={{ /*border: 'solid 0.1vh #515151',*/ height: '25vh' }}>
                        <Grid item xs={4} textAlign='center'  >
                            <Grid container>
                              {usuarioSeleccionado.permisos.map((region, regionIndex) => (
                                  <Grid container direction="column" key={regionIndex} style={{ overflowX: 'auto', whiteSpace: 'nowrap',  border:'solid 0.1vh  #515151'  }}>
                                    <p   style={{ fontSize: '1.7vh', margin: '0 5px' }}>{capitalize(String(region.REGION))}</p>
                                  </Grid>
                              ))}
                            </Grid>
                        </Grid>
                        <Grid item xs={4} textAlign='center' >
                          <Grid container>
                            {usuarioSeleccionado.permisos.map((region, regionIndex) => (
                                <Grid container direction="column" key={regionIndex} style={{ overflowX: 'auto', whiteSpace: 'nowrap',border:'solid 0.1vh  #515151'  }}>
                                  {region.AREAS.map((area, areaIndex) => (
                                      <p  key={`${regionIndex}-${areaIndex}`} style={{ fontSize: '1.7vh', margin: '0 5px' }}>{capitalize(String(area.AREA))}</p>
                                  ))}
                                </Grid>
                            ))}
                          </Grid>
                        </Grid>
                        <Grid item xs={4} >
                          <Grid container justifyContent='center'>
                            { editar === false && usuarioSeleccionado?.permisos.map((region, regionIndex) => (
                              <Grid container justifyContent='center' key={regionIndex} style={{ overflowX: 'auto', whiteSpace: 'nowrap', border:'solid 0.1vh #515151' }}>
                                {region?.AREAS.map((area, areaIndex) => (
                                    area?.CENTRO.map((centro, centroIndex) => (
                                      <p key={`${regionIndex}-${areaIndex}-${centroIndex}`} style={{ fontSize: '1.7vh', margin: '0 5px' }}>
                                        - {capitalize(String(centro.nombre))}
                                      </p>
                                    ))
                                ))}
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      </Grid>
                    
                  </Grid>
                </>
              )}
            </Grid>
            
            <Grid container justifyContent='center' height='5vh'>
              <Button style={{fontSize:'1.8vh'}} variant='outlined' color='inherit' onClick={handleCloseData}> {translate('salir')} </Button>
            </Grid>
          </Grid>
        </Modal>

    </Grid>
  );
};

export default Usuario;
