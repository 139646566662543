import React, { useState } from 'react';
import {
  Grid,
  Button,
  TextField,
  Alert,
  InputBase,
  Divider,
  IconButton,
} from '@mui/material';
import { LoginUsuario } from '../utils/ApiLogin';
import '../assets/css/Login.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Base from '../components/BaseLogos/Base';
import Logo from '../assets/img/LogoItg.png';
import Traductor from '../components/Traductor';
import { dotSpinner } from 'ldrs';
import TranslateIcon from '@mui/icons-material/Translate';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenIcon from '@mui/icons-material/LockOpen';

dotSpinner.register();

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: 'black',
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: 'rgb(255,255,255.01)',
        },
      },
    },
  },
});

const Login = ({ fileContent, translate, SWVersion, handleClick, openMenuIdioma, handleCloseIdioma, handleLanguageChange }) => {
  const [input_email, setInput_Email] = useState("");
  const [input_username, setInput_Username] = useState("");
  const [input_password, setInput_Password] = useState("");
  const [showEmailField, setShowEmailField] = useState(true);
  const [error, setError] = useState('');
  const [fallaConexion, setFallaConexion] = useState(false);
  const [cargando, setCargando] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleButtonClick = (fieldType) => {
    if (fieldType === 'Email') {
      setShowEmailField(true);
    } else if (fieldType === 'Nombre Usuario') {
      setShowEmailField(false);
    }
  };

  const handleLogin = async (event) => {
    event.preventDefault();  // Previene el comportamiento predeterminado del formulario
    setCargando(true);
    setFallaConexion(false);

    try {
      const login = await LoginUsuario({ input_username, input_password, input_email, fileContent });

      if (login) {
        console.log('Inicio de sesión exitoso');
        setFallaConexion(false);
        setCargando(false);
      } else {
        console.error('Error al iniciar sesión');
        setCargando(false);
        setFallaConexion(true);
        setError(translate('login_error_message'));
      }
    } catch (error) {
      console.error('Error al iniciar sesión:', error);
      setError(translate('login_error_message'));
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Grid container justifyContent="center" alignItems="center" className="root" style={{ cursor: 'default !important', userSelect: 'none', overflow: 'hidden' }}>
        <Grid container justifyContent="flex-end">
          <IconButton onClick={handleClick} style={{ marginRight: '1vw' }}>
            <TranslateIcon style={{ fontSize: '2vh', color: '#e84e0e' }} />
          </IconButton>
          <Traductor handleLanguageChange={handleLanguageChange} openMenuIdioma={openMenuIdioma} handleCloseIdioma={handleCloseIdioma} />
        </Grid>
        <Grid container justifyContent="center" item xs={3} className="paper" style={{ zIndex: 1 }}>
          <Grid container justifyContent="center">
            <Grid container justifyContent="center">
              <Button variant="h4" className="title">Login</Button>
            </Grid>
            <form onSubmit={handleLogin}>
              <Grid container className="buttonContainer">
                {/* Botones para cambiar entre Email y Nombre de Usuario */}
              </Grid>
              <Grid container justifyContent="center" className="form">
                <Grid container justifyContent='center'>
                  {showEmailField ? (
                    <Grid
                      component="div"
                      style={{ alignItems: 'center', width: '20vw', height: '6vh', border: 'solid 0.1vh #505151', borderRadius: '2vh', marginTop: '4vh' }}>
                      <Grid container justifyContent='center'>
                        <AccountCircleIcon style={{ fontSize: '1.5vw', marginTop: '1.2vh', marginLeft: '0.5vw', zIndex: 0 }} />
                        <Divider sx={{ height: '4vh', marginLeft: '0.5vw', marginTop: '0.8vh' }} orientation="vertical" />
                        <InputBase
                          style={{ marginLeft: '0.5vw', marginTop: '0.5vh', fontSize: '1.25vw', width: '15.5vw' }}
                          placeholder={translate('username')}
                          value={input_username}
                          onChange={(e) => setInput_Username(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <TextField
                      style={{ width: "20vw", marginLeft: "2.5vw" }}
                      label={translate('email')}
                      type="email"
                      onChange={(e) => setInput_Email(e.target.value)}
                    />
                  )}
                  <Grid
                      component="div"
                      style={{ alignItems: 'center', width: '20vw', height: '6vh', border: 'solid 0.1vh #505151', borderRadius: '2vh', marginTop: '4vh' }}>
                      <Grid container justifyContent='center'>
                        {!showPassword ? 
                          <LockOutlinedIcon  onClick={togglePasswordVisibility} style={{ fontSize: '1.5vw', marginTop: '1.2vh', marginLeft: '0.5vw', zIndex: 0 }} />
                        :
                          <LockOpenIcon  onClick={togglePasswordVisibility} style={{ fontSize: '1.5vw', marginTop: '1.2vh', marginLeft: '0.5vw', zIndex: 0 }} />
                        }
                        <Divider sx={{ height: '4vh', marginLeft: '0.5vw', marginTop: '0.8vh' }} orientation="vertical" />
                        <InputBase
                          style={{ marginLeft: '0.5vw', marginTop: '0.5vh', fontSize: '1.25vw', width: '15.5vw' }}
                          placeholder={translate('password')}
                          value={input_password}
                          onChange={(e) => setInput_Password(e.target.value)}
                          type={showPassword ? 'text' : 'password'}
                        />
                      </Grid>
                    </Grid>
                  {/* <Grid
                    component="div"
                    style={{ alignItems: 'center', width: '20vw', height: '6vh', border: 'solid 0.1vh #505151', borderRadius: '2vh', marginTop: '4vh' }}>
                    <Grid container justifyContent='center'>
                      <LockOutlinedIcon  onClick={togglePasswordVisibility} style={{ fontSize: '3.5vh', marginTop: '1.2vh', marginLeft: '0.5vw', zIndex: 0 }} />
                      <Divider sx={{ height: '4vh', marginLeft: '0.5vw', marginTop: '0.8vh' }} orientation="vertical" />
                      <InputBase
                        style={{ marginLeft: '0.5vw', marginTop: '0.5vh', fontSize: '2.5vh', width: '12vw' }}
                        placeholder={translate('password')}
                        value={input_password}
                        onChange={(e) => setInput_Password(e.target.value)}
                        type={showPassword ? 'text' : 'password'}
                      />
                      <Divider sx={{ height: '4vh', marginLeft: '0.5vw', marginTop: '0.8vh' }} orientation="vertical" />
                      <IconButton type="button" sx={{ marginTop: '0.5vh' }} onClick={togglePasswordVisibility}>
                        {showPassword ? <VisibilityIcon style={{ fontSize: '3.5vh', marginLeft: '0.5vw', zIndex: 0 }} /> : <VisibilityOffIcon style={{ fontSize: '3.5vh', marginLeft: '0.5vw', zIndex: 0 }} />}
                      </IconButton>
                    </Grid>
                  </Grid> */}
                </Grid>
                <Button
                  type="submit"
                  disabled={!fileContent}
                  size="medium"
                  variant="contained"
                  style={{ fontSize: '1vw', width: "15vw", height: '4.5vh', borderRadius: '2.5vh', marginTop: "5vh", backgroundColor: "#e84e0e", color: "white" }}
                >
                  {translate('login_button')}
                </Button>
                {cargando && (
                  <Grid container justifyContent='center' style={{ marginTop: '1vh' }}>
                    <l-dot-spinner
                      size="40"
                      speed="0.9"
                      color="white"
                    ></l-dot-spinner>
                  </Grid>
                )}
                {fallaConexion && (
                  <Alert severity="error" sx={{ mt: 2 }}>
                    {error}
                  </Alert>
                )}
              </Grid>
            </form>
            <Grid container justifyContent='center' style={{ marginTop: '25vh' }}>
              <h1 style={{ fontSize: '0.75vw', color: 'rgb(255,255,255,0.2)' }}>
                {SWVersion}
              </h1>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent='center' item xs={8} style={{ width: '50vw', marginLeft: '2vw', alignItems: 'center' }}>
          <img src={Logo} className="rotating-image" style={{ height: '30vh', cursor: 'default !important', userSelect: 'none' }} />
        </Grid>
        <Grid item xs={12} style={{ marginTop: '2.5vh' }}>
          <Base />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Login;
