import React, { useState, useEffect  } from 'react';
// import Fade from 'react-reveal/Fade';
// import TransitionGroup from 'react-transition-group/TransitionGroup';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Scrollbar, Mousewheel, EffectCoverflow, Pagination  } from 'swiper/modules';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import RegionSelector from './SeleccionAcceso/Region';
import AreaSelector from './SeleccionAcceso/Area';
import CenterSelector from './SeleccionAcceso/Centro';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import CardReggions from './CardRegions/CardReggions';
import { fetchConsolidadoCenters } from '../../utils/apiServer';

function capitalize(value) {
  if (typeof value !== 'string') {
    // console.error("Expected a string in capitalize function, but got:", typeof value);
    return '';
  }

  // Divide la cadena en palabras
  let words = value.toLowerCase().split(' ');

  // Capitaliza la primera letra de cada palabra
  let capitalizedWords = words.map(word => {
    // Capitaliza la primera letra de la palabra
    let capitalized = word.charAt(0).toUpperCase() + word.slice(1);
    return capitalized;
  });

  // Une las palabras nuevamente en una cadena
  let capitalized = capitalizedWords.join(' ');

  // Si la cadena comienza con 'Los', agrega un espacio después de 'Los'
  if (capitalized.startsWith('Los')) {
    capitalized = capitalized.replace('Los', 'Los ');
  }

  // Si la cadena comienza con 'Puerto', agrega un espacio después de 'Puerto'
  if (capitalized.startsWith('Puerto')) {
    capitalized = capitalized.replace('Puerto', 'Puerto ');
  }

  return capitalized;
}




const Acceso = ({user, setUser, translate}) => {
  const [region, setRegion] = useState('');
  const [area, setArea] = useState('');
  const [selectedCenters, setSelectedCenters] = useState({});
  const [showAreaSelector, setShowAreaSelector] = useState(false);
  const [selectedRegionData, setSelectedRegionData] = useState(null);
  const [selectedAreaData, setSelectedAreaData] = useState(null);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true); // Nuevo estado
  const [openAlert, setOpenAlert] = useState(false);
  const [mensajeAlert, setMensajeAlert] = useState('');

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

      //*--------------------- Obtener las regiones, áreas y centros del cliente actual 'regionsData' ------------------------------
    //? Descripción:
    // Utiliza la información de los centros (obtenida de la función getAvailableCenters) para mapear
    // y estructurar los datos de las regiones, áreas y centros. Crea un array de objetos donde cada objeto
    // representa una región, con sus respectivas áreas y centros asociados.
    // Cada región contiene un identificador único ('clientId'), el nombre de la región ('name'), y un array de áreas.
    // Cada área tiene un nombre ('name') y un array de centros asociados.
    // Los centros están determinados por la primera área de cada región.
    // El resultado se almacena en la variable 'regionsData'.
    // Además, se inicializan estados para la región, área y centro seleccionados.

      // Recuperar el objeto desde localStorage
      // const rac = [JSON.parse(localStorage.getItem('Regiones'))];

      // const regionsData = rac.map((clientData, index) => {
      //     const regions = Object.keys(clientData);
          
      //     return regions.map(region => {
      //         const AREAS = Object.keys(clientData[region]);
              
      //         // Mapea las áreas y asigna los centros específicos de cada área
      //         const mappedAreas = AREAS.map(area => {
      //             const centers = clientData[region][area];
      //             return {
      //                 AREA: area,
      //                 CENTRO: centers,
      //             };
      //         });
      
      //         return {
      //             REGION: region,
      //             AREAS: mappedAreas,
      //         };
      //     });
      // }).flat();

      const [consolidado_estados, setConsolidado_estados] = useState({});

      useEffect(() => {
        const fetchData = async () => {
            let PAGE ='HOME'
            const result = await fetchConsolidadoCenters(PAGE);
            setConsolidado_estados(result);
            // console.log('response: consolidado_estados', result)
  
        };
  
        // Fetch data initially
        fetchData();
  
        // Set interval to fetch data every 60 seconds
        const interval = setInterval(fetchData, 60000);
  
        // Clean up interval on component unmount
        return () => clearInterval(interval);
    }, []);

    function transformData(consolidado_estados) {
      const { areas, centros, regiones } = consolidado_estados;
      const transformedData = [];
  
      // Recorrer las regiones
      for (const regionName in regiones) {
          const region = {
              name: regionName,
              statusRegions: regiones[regionName],
              areas: []
          };
  
          // Recorrer las áreas
          for (const areaName in areas) {
              const [regionPrefix, areaPrefix] = areaName.split('-');
  
              if (regionPrefix === regionName) {
                  const area = {
                      name: areaPrefix,
                      statusArea: areas[areaName],
                      centers: []
                  };
  
                  // Recorrer los centros
                  for (const centerName in centros[areaName]) {
                      const [centerPrefix] = centerName.split('-');
  
                      const center = {
                          name: centerPrefix,
                          statusCenter: centros[areaName][centerName]
                      };
  
                      area.centers.push(center);
                  }
  
                  region.areas.push(area);
              }
          }
  
          transformedData.push(region);
      }
  
      return transformedData;
  }
  // console.log('response: consolidado_estados', transformData(consolidado_estados))


      const handleRegionChange = (event) => {
        const selectedRegionName = event;
        setRegion(selectedRegionName);
        const selectedRegionData = transformData(consolidado_estados).find(region => region.name === selectedRegionName);
        setSelectedRegionData(selectedRegionData);
        setArea('');
        setSelectedCenters([]);
      };
    
      const handleAreaChange = (event) => {
        const selectedArea = event;
        setArea(selectedArea);
        const areaData = selectedRegionData?.areas.find((area) => area.name === selectedArea);
        setSelectedAreaData(areaData);
        setSelectedCenters([]);
      };
    
      const handleCenterChange = (event) => {
        setSelectedCenters(event);
      };

      // console.log('user ',user )
    
      const add = (event) => {
        event.preventDefault();
        const updatedUser = { ...user };
    
        // Asegúrate de que permisos es un array
        if (!Array.isArray(updatedUser.permisos)) {
            updatedUser.permisos = [];
        }
    
        const regionIndex = updatedUser.permisos.findIndex(permiso => permiso.REGION && permiso.REGION[0] === region);
        if (regionIndex !== -1) {
            const areaIndex = updatedUser.permisos[regionIndex].AREAS.findIndex(areaData => areaData.AREA === area);
            if (areaIndex !== -1) {
                // El área ya existe, actualizar los centros existentes
                const existingCenters = updatedUser.permisos[regionIndex].AREAS[areaIndex].CENTRO;
                Object.keys(selectedCenters).forEach(centerName => {
                    if (!existingCenters.hasOwnProperty(centerName)) {
                        existingCenters[centerName] = {
                            enviro: selectedCenters[centerName].enviro,
                            nanoox: selectedCenters[centerName].nanoox,
                            scapp: selectedCenters[centerName].scapp,
                            pss: selectedCenters[centerName].pss,
                        };
                    } else {
                        // Si el centro ya existe, podrías querer actualizar sus propiedades aquí
                        existingCenters[centerName].enviro = selectedCenters[centerName].enviro;
                        existingCenters[centerName].nanoox = selectedCenters[centerName].nanoox;
                        existingCenters[centerName].scapp = selectedCenters[centerName].scapp;
                        existingCenters[centerName].pss = selectedCenters[centerName].pss;
                    }
                });
            } else {
                // El área no existe, agregar nueva área con sus centros
                const newCenters = {};
                Object.keys(selectedCenters).forEach(centerName => {
                    newCenters[centerName] = {
                        enviro: selectedCenters[centerName].enviro,
                        nanoox: selectedCenters[centerName].nanoox,
                        scapp: selectedCenters[centerName].scapp,
                        pss: selectedCenters[centerName].pss,
                    };
                });
                updatedUser.permisos[regionIndex].AREAS.push({
                    AREA: area,
                    CENTRO: newCenters,
                });
            }
        } else {
            // La región no existe, agregar nueva región con su área y centros
            const newCenters = {};
            Object.keys(selectedCenters).forEach(centerName => {
                newCenters[centerName] = {
                    enviro: selectedCenters[centerName].enviro,
                    nanoox: selectedCenters[centerName].nanoox,
                    scapp: selectedCenters[centerName].scapp,
                    pss: selectedCenters[centerName].pss,
                };
            });
            updatedUser.permisos.push({
                REGION: [region],
                AREAS: [
                    {
                        AREA: area,
                        CENTRO: newCenters,
                    },
                ],
            });
        }
    
        setUser(updatedUser);
        setRegion('');
        setArea('');
        setSelectedCenters({});
      };
      const handleCenterDelete = (regionIndex, areaIndex, centerToDelete) => () => {
        const newData = [...user.permisos];
        const updatedCenters = { ...newData[regionIndex].AREAS[areaIndex].CENTRO };
      
        // Eliminar el centro basado en su nombre
        if (updatedCenters.hasOwnProperty(centerToDelete)) {
          delete updatedCenters[centerToDelete];
      
          newData[regionIndex].AREAS[areaIndex].CENTRO = updatedCenters;
      
          const updatedUser = {
            ...user,
            permisos: newData,
          };
      
          setUser(updatedUser);
        }
      };
    
      const toggleAreaSelector = () => {
        setShowAreaSelector(!showAreaSelector);
      };
    
      const saveArea = (regionIndex) => {
        const newData = [...user.permisos];
        const areaExists = newData[regionIndex].AREAS.some(areaData => areaData.AREA === area);
        if (areaExists) {
          setOpenAlert(true);
          setMensajeAlert('La Área ya existe.');
          return;
        }
    
        newData[regionIndex].AREAS.push({
          AREA: area,
          CENTRO: [selectedCenters],
        });
    
        setArea('');
        setSelectedCenters([]);
        toggleAreaSelector();
    
        const updatedUser = {
          ...user,
          permisos: newData,
        };
    
        setUser(updatedUser);
      };
    
      const remove = (regionIndex, areaIndex) => {
        const updatedPermisos = [...user.permisos];
        if (areaIndex !== undefined) {
          updatedPermisos[regionIndex].AREAS.splice(areaIndex, 1);
        } else {
          updatedPermisos.splice(regionIndex, 1);
        }
        const updatedUser = { ...user, permisos: updatedPermisos };
        setUser(updatedUser);
      };
        // Function to add all regions, areas, and centers
      const addAllRegions = () => {
        const allPermisos = transformData(consolidado_estados).map(region => ({
          REGION: [region.name],
          AREAS: region.name.map(area => ({
            AREA: area.name,
            CENTRO: area.CENTRO,
          }))
        }));

        const updatedUser = {
          ...user,
          permisos: allPermisos,
        };

        setUser(updatedUser);
      };
      //console.log('user',user)
      useEffect(() => {
        if (user.cargo === 'Gerencia' && user.permisos.length === 0) {
          addAllRegions();
        }
      }, []);
  
    
      useEffect(() => {
        if (!Array.isArray(user.selectedCenters)) {
          setSelectedCenters([]);
        } else {
          setSelectedCenters(user.selectedCenters);
        }
      }, [user]);
    
      useEffect(() => {
        setIsAddButtonDisabled(!(region && area &&  Object.keys(selectedCenters).length > 0));
      }, [region, area, selectedCenters]);


    
      return (
        <Grid container>
          <Grid container justifyContent="center" item xs={12} style={{zIndex:1}}>
            <Grid item xs={2} style={{ marginLeft: "10vw" }}>
              <RegionSelector capitalize={capitalize} translate={translate} region={region} datosArray={transformData(consolidado_estados)} handleRegionChange={handleRegionChange} />
            </Grid>
            <Grid item xs={2} style={{ marginLeft: "2vw" }}>
              <AreaSelector capitalize={capitalize}  translate={translate} region={region} area={area} selectedRegionData={selectedRegionData} handleAreaChange={handleAreaChange} />
            </Grid>
            <Grid item xs={2} style={{ marginLeft: "2vw" }}>
              <CenterSelector capitalize={capitalize}  translate={translate} selectedCenters={selectedCenters} selectedAreaData={selectedAreaData} handleCenterChange={handleCenterChange} />
            </Grid>
            <Grid item xs={2} style={{ marginLeft: "2vw" }}>
              <Button onClick={add} disabled={isAddButtonDisabled} variant="outlined" color="primary" type="submit" style={{fontSize:'1.8vh', marginTop:'0.5vh'}}>
                {translate('add_item')}
              </Button>
            </Grid>
          </Grid>
          {openAlert && (
            <Alert severity="warning" onClose={handleCloseAlert} style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999 }}>
              {translate(mensajeAlert)}
            </Alert>
          )}
          <Grid container justifyContent='center' style={{zindex:0}}> 
            <CardReggions
              // dataFlase={dataFlase}
              datosArray={transformData(consolidado_estados)}
              user={user}
              remove={remove}
              translate={translate}
              handleCenterChange={handleCenterChange}
              handleAreaChange={handleAreaChange}
              selectedAreaData={selectedAreaData}
              selectedRegionData={selectedRegionData}
              area={area}
              showAreaSelector={showAreaSelector}
              saveArea={saveArea}
              selectedCenters={selectedCenters}
              toggleAreaSelector={toggleAreaSelector}
              handleCenterDelete={handleCenterDelete}
              capitalize={capitalize}
            />
          </Grid>
          
        </Grid>
  );
}

export default Acceso;
