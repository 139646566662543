import React, { useState, useEffect } from 'react';
import {  ButtonGroup, Fab , Button,IconButton, Grid, Box } from '@mui/material';
import Main_Bombas from '../component/layout/Main_Bombas';
import { fetchAlertas, fetchFallas } from '../utils/ApiServer';
import NavigationIcon from '@mui/icons-material/Navigation';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Alertas from '../component/Alertas';
import Fallas from '../component/Fallas';


const Home = ({center_data,centroSeleccionado,translate,mostrarAlertas, controlType}) => {

const [modulosPorPagina, setModulosPorPagina]         = useState (2);
const [alertas, setAlertas] = useState([]);
const alertasFiltradas = alertas?.filter(alerta =>  alerta?.rango !== 0);
const alertasFiltradasBuenas = alertas?.filter(alerta =>  alerta?.rango === 0);
const [fallas, setFallas] = useState([]);
const fallasSinAlerta = fallas?.filter(falla => !falla?.alerta);
const fallasSinAlertaBuenas = fallas?.filter(falla => falla?.alerta);
const [tipoSeleccionada, setTipoSeleccionada] = useState('');

const [alertaSeleccionada, setAlertaSeleccionada] = useState(null); // Estado para la alerta seleccionada
const [fallaSeleccionada, setFallaSeleccionada] = useState(null); // Estado para la alerta seleccionada

// const isSingleModule = datos && datos.modulos && datos.modulos.length === 1;
console.log( alertaSeleccionada)
  const [page, setPage] = useState(1);
  const totalModules = center_data?.modulos?.length || 0;

 

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleNextPage = () => {
    setPage((prevPage) => Math.min(prevPage + 1, Math.ceil(totalModules / modulesPerPage)));
  };

  const handlePrevPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleAlertClick = (alerta, tipo) => {
    setTipoSeleccionada(tipo)

    if(tipo === 'alerta'){
      setAlertaSeleccionada(alerta);
    }
    else if(tipo == 'Falla'){
      setFallaSeleccionada(alerta)
    }
  };


  const getSensorName = (sensor) => {
    switch(sensor) {
        case 'O2': return     translate('oxigeno');
        case 'DO2': return    translate('saturacion');
        case 'Vo': return     translate('voltaje');
        case 'Io': return     translate('corriente');
        case 'Press': return  translate('presion');
        default: return sensor;
    }
  };
  
  const getFallaName = (sensor) => {
    switch(sensor) {
      case 'device_com': return translate('device_com_falla');
      case 'sensor_com': return translate('sensor_com_falla');
      case 'device': return translate('device_falla');
      case 'sensor': return translate('sensor_falla');
      default: return sensor;
    }
  };
  
  const getSensorFallaEstable = (falla) => {
    switch(falla) {
      case 'device_com': return translate('device_com_falla_e');
      case 'sensor_com': return translate('sensor_com_falla_e');
      case 'device': return translate('device_falla_e');
      case 'sensor': return translate('sensor_falla_e');
      default: return falla;
    }
  };
  

  const modulesPerPage = 1;
  const startIndex = (page - 1) * modulesPerPage;
  const endIndex = startIndex + modulesPerPage;
  // * alertas Activas
  useEffect(() => {
          const fetchData = async () => {
              const loggedIn = true
              const data = await fetchAlertas(loggedIn);
              if (Array.isArray(data)) {
                  setAlertas(data);
                  console.log('data alertas',data)
              } else {
                  // Manejar el caso en el que no se recibe un array
                  console.error('fetchAlertas no devolvió un array válido:', data);
              }
          };
      
          // Ejecutar la función inmediatamente al cargar el componente
          fetchData();
      
          // Configurar el intervalo para ejecutar la función cada minuto
          const interval = setInterval(fetchData, 30000);
      
          // Limpiar el intervalo al desmontar el componente
          return () => clearInterval(interval);
      }, []);
  // * fallas Activas
  useEffect(() => {
    const fetchData = async () => {
        const loggedIn = true
        const data = await fetchFallas(loggedIn);
        if (Array.isArray(data)) {
            setFallas(data);
            console.log('data fallas',data)

        } else {
            // Manejar el caso en el que no se recibe un array
            //console.error('fetchAlertas no devolvió un array válido:', data);
        }
    };

    // Ejecutar la función inmediatamente al cargar el componente
    fetchData();

    // Configurar el intervalo para ejecutar la función cada minuto
    const interval = setInterval(fetchData, 30000);

    // Limpiar el intervalo al desmontar el componente
    return () => clearInterval(interval);
  }, []);

  return (
    <Grid container justifyContent="center" style={{ width:"99vw", height:"90vh", overflow:'hidden', }}>
      {mostrarAlertas &&
        <Grid container justifyContent={'center'} alignContent={'flex-start'} item xs={2}  style={{ maxWidth: '14vw', width:'14vw', height:'90vh'}}>
          <Grid container justifyContent={'center'}>
            {tipoSeleccionada === 'alerta' ?
              <Alertas alertaSeleccionada={alertaSeleccionada} fallaSeleccionada={fallaSeleccionada} translate={translate}/>
            :
              <Fallas fallaSeleccionada={fallaSeleccionada} translate={translate}/>
            }

          </Grid>
          <Grid container justifyContent={'center'}>
            <Grid container justifyContent={'center'} alignContent={'center'} alignItems={'center'} item xs={11} style={{fontSize:'1vw', color:'#ffffff', marginBottom:'1vh',  height:'4vh',}}>
              <strong>{translate('alertas_activas')} : {alertasFiltradas?.length} </strong>
              {/* <strong>Alertas Activas: {alertasFiltradasBuenas?.length} </strong> */}
              {/* Detalles de la Alerta Seleccionada */}
            </Grid>
          </Grid>
          <Grid container justifyContent={'center'} style={{ maxHeight:'25vh',  overflow:'auto'}}>
            {alertas.slice().reverse().map((alerta, alerta_id)=>{
              const isSelected = alerta === alertaSeleccionada; // Verifica si la alerta actual es la seleccionada
              const [modulo, hf, jaula, bomba, aviot,sensor] = alerta?.variable?.split('-') ? alerta?.variable?.split('-') : '';
              const [año, mes, dia, hora ] = alerta?.fecha?.split('-') ? alerta?.fecha?.split('-') : '';
              const tipo = 'alerta'
              const Sensor = alerta?.variable?.split('-')[3] 

              // console.log('Sensor',Sensor)
              return(
                <Grid container justifyContent={'center'} alignContent={'center'} style={{height:'5vh'}} item xs={11} key={alerta_id}> 
                  <ButtonGroup fullWidth orientation="vertical" aria-label="Vertical button group" style={{ height:'5vh' ,backgroundColor: isSelected ? '#505050' : (alerta_id % 2 === 0 ? '#1b1b1b' : '#0b0b0b')}}>
                    <Button fullWidth  onClick={() => handleAlertClick(alerta, tipo)} variant='text' color='inherit' style={{ height:'5vh' ,backgroundColor: isSelected ? '#505050' : (alerta_id % 2 === 0 ? '#1b1b1b' : '#0b0b0b')}} >
                      <Grid container justifyContent={'center'} alignContent={'center'}> 
                        <Grid container justifyContent={'center'} item xs={3} style={{fontSize:'0.75vw'}}>
                          {getSensorName(sensor)} 
                        </Grid>
                        <Grid container justifyContent={'center'} item xs={6} style={{fontSize:'0.75vw'}}>
                          {hora} 
                        </Grid>
                        <Grid container justifyContent={'center'}item xs={1}>
                          {alerta.rango === 0 ?
                            <CheckCircleIcon  style={{fontSize:'1.15vw', color:'green', marginLeft:'-0.1vw'}}/>
                          :
                            <ReportProblemIcon  style={{fontSize:'1.15vw', color:'red', marginLeft:'-0.1vw'}}/>
                          }
                        </Grid>
                      </Grid>
                    </Button>
                  </ButtonGroup>
                </Grid>             
              )
            })}
            {alertas.length === 0 && (
              <Grid container justifyContent={'center'} alignContent={'center'} item xs={11} style={{borderRadius:'0.5vw', height:'4vh', backgroundColor:'#1b1b1b'}}>
                {translate('sin_alertas_activas')}
              </Grid>
            )}

          </Grid>
          <Grid container justifyContent={'center'}>
            <Grid container justifyContent={'center'} alignContent={'center'} alignItems={'center'} item xs={11} style={{fontSize:'1vw', color:'#ffffff', marginBottom:'1vh',  height:'4vh'}}>
              <strong>{translate('fallas_activas')}: {fallasSinAlerta?.length}</strong>
              {/* Detalles de la Alerta Seleccionada */}
            </Grid>
          </Grid>
          <Grid container justifyContent={'center'} style={{ maxHeight:'25vh', overflow:'auto'}}>
            {fallas.reverse().map((alerta, alerta_id)=>{
              const isSelected = alerta === fallaSeleccionada; // Verifica si la alerta actual es la seleccionada
              const [modulo, hf, jaula, bomba, aviot,sensor] = alerta?.variable?.split('-') ? alerta?.variable?.split('-') : '';
              const [año, mes, dia, hora ] = alerta?.fecha?.split('-') ? alerta?.fecha?.split('-') : '';
              const tipo = 'Falla'
              // console.log('fallas',alerta)
              return(
                <Grid container justifyContent={'center'} alignContent={'center'} style={{height:'7vh'}} key={alerta_id} item xs={11}> 
                  <ButtonGroup fullWidth orientation="vertical" aria-label="Vertical button group" style={{ height:'7vh' ,backgroundColor: isSelected ? '#505050' : (alerta_id % 2 === 0 ? '#1b1b1b' : '#0b0b0b')}}>
                    <Button fullWidth  onClick={() => handleAlertClick(alerta, tipo)} variant='text' color='inherit' style={{ height:'7vh' ,backgroundColor: isSelected ? '#505050' : (alerta_id % 2 === 0 ? '#1b1b1b' : '#0b0b0b')}} >
                      <Grid container justifyContent={'center'} alignContent={'center'}> 
                        <Grid  container justifyContent={'center'} alignContent={'center'} item xs={11} >
                          <Grid container justifyContent={'center'} alignContent={'center'} item xs={12} style={{fontSize:'0.65vw',  height:'3.5vh'}}>
                            {/* {getFallaName(sensor)}  */}
                            { alerta.alerta ? getSensorFallaEstable(sensor) : getFallaName(sensor) }
                          </Grid>
                          
                          <Grid container justifyContent={'center'} alignContent={'center'} item xs={12} style={{fontSize:'0.65vw',  height:'2vh',}}>
                            {hora} 
                          </Grid>
                        </Grid>
                        <Grid container justifyContent={'center'} alignContent={'center'} item xs={1}>
                          {alerta.alerta === true ?
                            <CheckCircleIcon  style={{fontSize:'1.15vw', color:'green', marginLeft:'-0.1vw'}}/>
                          :
                            <ErrorIcon  style={{fontSize:'1.15vw', color:'red', marginLeft:'-0.1vw'}}/>
                          }
                        </Grid>
                      </Grid>
                    </Button>
                  </ButtonGroup>
                </Grid>             
              )
            })}
            {/* <Grid  container justifyContent={'flex-start'} style={{backgroundColor:'#d32f2f', height:'7vh',width:'4vw', margin: '1vw 1vw'}}> hola </Grid>
            <Grid  container justifyContent={'flex-start'} style={{backgroundColor:'#e89823', height:'7vh', margin: '1vw 1vw'}}> hola </Grid> */}
            {fallas.length === 0 && (
              <Grid container justifyContent={'center'} alignContent={'center'} item xs={11} style={{borderRadius:'0.5vw', height:'4vh', backgroundColor:'#1b1b1b'}}>
                {translate('sin_fallas_activas')}
              </Grid>
            )}
          </Grid>
        </Grid >
      }
      <Grid container justifyContent={'center'} item xs={mostrarAlertas ? 10 : 12} style={{ maxWidth: mostrarAlertas ? '85vw' :'95vw'}}>
        {centroSeleccionado && center_data && center_data.modulos && (
          <Grid container justifyContent="flex-end">
              {center_data && center_data.modulos && center_data.modulos.slice(startIndex, endIndex).map((modulo, index) => {
                // console.log('%c modulo:', 'color: red; font-size:30px ', modulo);
                return(
                  <Grid container justifyContent="center" key={modulo.id}  style={{
                      maxWidth:  mostrarAlertas ? '85vw' :'95vw',
                      height: '90vh', // Adjust the height based on the number of modules
                      // height: isSingleModule ? '78vh' : '39vh', // Adjust the height based on the number of modules
                      width: mostrarAlertas ? '85vw' :'95vw',
                      flexGrow: 1,
                      // backgroundColor: '#0d0d0d' ,
                      flexDirection: 'column',
                      alignItems: 'center', // Centrar verticalmente
                      justifyContent: 'center', // Centrar horizontalmente
                      // marginTop: index === 1 ? '1vh' : '2vh',
                      // border:'solid 0.1vh',
                      borderRadius:'1vh',
                      marginLeft:"11vw",
                      
                      
                  }}>
                    <Grid container justifyContent="center" alignContent='center' style={{ height:'6vh',}}>
                      <Grid container justifyContent={'center'} item xs={3} style={{  }}>
                        <IconButton onClick={handlePrevPage} disabled={page === 1}>
                          <KeyboardArrowLeftIcon style={{fontSize:'1.2vw'}}/>
                        </IconButton>
                      </Grid>
                      <Grid  container justifyContent={'center'} item xs={3} >
                        <h1 style={{fontSize:"3vh"}}> {translate('modulo')} {modulo.id}</h1>
                      </Grid>
                      <Grid container justifyContent={'center'} item xs={3} style={{  }}>
                        <IconButton onClick={handleNextPage} disabled={page === Math.ceil(totalModules / modulesPerPage)}>
                          <KeyboardArrowRightIcon style={{fontSize:'1.2vw'}}/>
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent="center" style={{  height:'82vh', overflowY:'auto', overflowX:'hidden'}}>
                        <Main_Bombas translate={translate} modulo={modulo} indiceModulo={index} modulosPorPagina={modulosPorPagina} controlType={controlType}/>
                    </Grid>
                  
                  </Grid>
                )
              })}
          </Grid>
        )}
      </Grid>
   </Grid>
  )
}

export default Home