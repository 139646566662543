import React, { useState } from 'react';
import { Grid, Button } from '@mui/material';

function capitalize(value) {
  if (typeof value !== 'string') {
    // console.error("Expected a string in capitalize function, but got:", typeof value);
    return '';
  }

  // Divide la cadena en palabras
  let words = value.toLowerCase().split(' ');

  // Capitaliza la primera letra de cada palabra
  let capitalizedWords = words.map(word => {
    // Capitaliza la primera letra de la palabra
    let capitalized = word.charAt(0).toUpperCase() + word.slice(1);
    return capitalized;
  });

  // Une las palabras nuevamente en una cadena
  let capitalized = capitalizedWords.join(' ');

  // Si la cadena comienza con 'Los', agrega un espacio después de 'Los'
  if (capitalized.startsWith('Los')) {
    capitalized = capitalized.replace('Los', 'Los ');
  }

  // Si la cadena comienza con 'Puerto', agrega un espacio después de 'Puerto'
  if (capitalized.startsWith('Puerto')) {
    capitalized = capitalized.replace('Puerto', 'Puerto ');
  }

  return capitalized;
}

const Verificacion = ({ translate, user, handleConfirmation, editar, confiramcionUserEdit }) => {
  console.log('user',user)
  return (
    <>
      <Grid container direction="column" alignItems="center" style={{ height: '60vh', overflowX: 'auto' }}>
        <Grid container justifyContent='center' style={{ height: '5vh', marginTop:'2vh' }}>
          <h3 style={{ fontSize: '2.5vh', marginTop: '0.1vh' }}>{translate('user')}</h3>
        </Grid>
        <Grid container justifyContent='center' style={{ height: '8vh' }}>
          <Grid container justifyContent='center'>
            <Grid item xs={4} style={{ fontSize: '2.5vh', textAlign: 'center',  border: 'solid 0.1vh #515151', height:'4vh' }}>{translate('username')}</Grid>
            <Grid item xs={4} style={{ fontSize: '2.5vh', textAlign: 'center',  border: 'solid 0.1vh #515151', height:'4vh' }}>{translate('email')}</Grid>
            <Grid item xs={4} style={{ fontSize: '2.5vh', textAlign: 'center',  border: 'solid 0.1vh #515151', height:'4vh' }}>{translate('cargo')}</Grid>
          </Grid>
          <Grid container justifyContent='center'>
            <Grid item xs={4} style={{ fontSize: '1.7vh', textAlign: 'center',  border: 'solid 0.1vh #515151', height:'4vh' }}>{user.username}</Grid>
            <Grid item xs={4} style={{ fontSize: '1.7vh', textAlign: 'center',  border: 'solid 0.1vh #515151', height:'4vh' }}>{user.correo}</Grid>
            <Grid item xs={4} style={{ fontSize: '1.7vh', textAlign: 'center',  border: 'solid 0.1vh #515151', height:'4vh' }}>{user.cargo}</Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent='center' style={{ height: '5vh', marginTop:'1vh' }} >
          <h3 style={{ fontSize: '2.5vh', marginTop: '0.1vh' }}>{translate('permisos')}</h3>
        </Grid>
        <Grid container justifyContent='center' style={{ /*border: 'solid 0.1vh #515151',*/ height: '25vh' }}>
            <Grid container justifyContent='center' alignContent='center' item xs={4} border=' solid 0.1vh #515151' style={{fontSize:'2vh', height:'4vh'}}>
              {translate('region')}
            </Grid>
            <Grid container justifyContent='center'  alignContent='center' item xs={4} border=' solid 0.1vh #515151' style={{fontSize:'2vh', height:'4vh'}}>
              {translate('area')}
            </Grid>
            <Grid container justifyContent='center'  alignContent='center' item xs={4} border=' solid 0.1vh #515151' style={{fontSize:'2vh', height:'4vh'}}>
              {translate('centro')}
            </Grid>
            <Grid  container justifyContent='center' style={{ /*border: 'solid 0.1vh #515151',*/ height: '25vh' }}>
              <Grid item xs={4} textAlign='center'  >
                  <Grid container>
                    {user.permisos.map((region, regionIndex) => (
                        <Grid container direction="column" style={{ overflowX: 'auto', whiteSpace: 'nowrap',  border:'solid 0.1vh  #515151'  }} key={regionIndex}>
                          <p style={{ fontSize: '1.7vh', margin: '0 5px' }}>{capitalize(String(region.REGION))}</p>
                        </Grid>
                    ))}
                  </Grid>
              </Grid>
              <Grid item xs={4} textAlign='center' >
                <Grid container>
                  {user.permisos.map((region, regionIndex) => (
                      <Grid container direction="column" style={{ overflowX: 'auto', whiteSpace: 'nowrap',border:'solid 0.1vh  #515151'  }}  key={`${regionIndex}`}>
                        {region.AREAS.map((area, areaIndex) => (
                            <p  key={`${regionIndex}-${areaIndex}`} style={{ fontSize: '1.7vh', margin: '0 5px' }}>{capitalize(String(area.AREA))}</p>
                        ))}
                      </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={4} >
                <Grid container justifyContent='center'>
                  {user.permisos.map((region, regionIndex) => (
                    <Grid container justifyContent='center' key={regionIndex} style={{ overflowX: 'auto', whiteSpace: 'nowrap', border:'solid 0.1vh #515151' }}>
                      {region.AREAS.map((area, areaIndex) => (
                          // area.CENTRO.map((centro, centroIndex) => (
                          //   <p key={`${regionIndex}-${areaIndex}-${centroIndex}`} style={{ fontSize: '1.7vh', margin: '0 5px' }}>
                          //     - {capitalize(String(centro))}
                          //   </p>
                          // ))
                          Object.keys(area.CENTRO).map((centerItem, centerIndex) => {
                            return(
                            <p key={`${regionIndex}-${areaIndex}-${centerIndex}`} style={{ fontSize: '1.7vh', margin: '0 5px' }}>
                              - {capitalize(String(centerItem))}
                            </p>
                            )
                          })
                      ))}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
           
        </Grid>
        

        {/* Botón para guardar los datos */}
        {!editar ?
          <Button
            size="large"
            className="button"
            type="button"
            variant="outlined"
            color="inherit"
            onClick={() => handleConfirmation(user)}
          >
            {translate('guardar_user')}
          </Button>
        :
          <Button
            size="large"
            className="button"
            type="button"
            variant="outlined"
            color="inherit"
            onClick={() => confiramcionUserEdit(user)}
            style={{ fontSize: '2vh' }}
          >
            Editar Usuario
          </Button>
        }
      </Grid>

    </>
  );
};

export default Verificacion;
