
// import React, { useState, useRef, useEffect } from 'react';
// import { FormControl, InputLabel, MenuItem, Paper, InputBase, Divider, IconButton, Grid, Checkbox, ListItemText } from '@mui/material';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// const CustomSelect = ({ label, value, onChange, selectedAreaData }) => {
//   console.log('selectedAreaData',selectedAreaData)
//   const [open, setOpen] = useState(false);
//   const wrapperRef = useRef(null);

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
//         setOpen(false);
//       }
//     };

//     document.addEventListener('mousedown', handleClickOutside);

//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, [wrapperRef]);

//   const handleOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   const handleMenuItemClick = (regionName) => {
//     const newValue = value.includes(regionName)
//       ? value.filter(item => item !== regionName)
//       : [...value, regionName];
//     onChange(newValue);
//   };

//   return (
//     <FormControl fullWidth>
//       <Grid container justifyContent='center'>
//         <InputLabel style={{ fontSize: '1.8vh' }}>{label}</InputLabel>
//         <InputBase
//           fullWidth
//           value={value.join(', ')} // Mostrar valores seleccionados
//           onClick={handleOpen}
//           inputProps={{ readOnly: true }}
//           style={{ marginTop: '1vh', marginLeft: '-1.5vw', fontSize: '1.8vh', zIndex: 1, width:'10vw', overflow:'auto' }}
//         />
//         <Divider sx={{ height: '3.5vh', marginLeft: '9vw', marginTop: '-3.7vh' }} orientation="vertical" />
//         <IconButton sx={{ marginTop: '-3.5vh', marginLeft: '10.9vw' }} onClick={open ? handleClose : handleOpen}>
//           {open ? <KeyboardArrowUpIcon style={{ fontSize: '2vh' }} /> : <KeyboardArrowDownIcon style={{ fontSize: '2vh' }} />}
//         </IconButton>
//       </Grid>
//       {open && (
//         <Paper ref={wrapperRef}>
//           {selectedAreaData && selectedAreaData.centers &&
//             selectedAreaData.centers.map((centro) => (
//               <MenuItem
//                 style={{ fontSize: '1.8vh' }}
//                 key={centro.name}
//                 value={centro.name}
//                 onClick={() => handleMenuItemClick(centro.name)}
//                 sx={{
//                   fontSize: '1.8vh',
//                   backgroundColor: value.includes(centro.name) ? 'rgba(255, 255, 255, 0.1)' : 'inherit', // Cambia el fondo si está seleccionado
//                   color: value.includes(centro.name) ? '#fff' : 'inherit', // Cambia el color del texto si está seleccionado
//                 }}
//               >
//                 {/* <Checkbox style={{ fontSize: '1.8vh', width:'2vw', height:'2vh' }} checked={value.includes(centro.name)} /> */}
//                 <ListItemText primary={centro.name} sx={{ fontSize: '1.8vh' }}  />
//               </MenuItem>
//             ))}
//         </Paper>
//       )}
//     </FormControl>
//   );
// };

// const CenterSelector = ({ translate, selectedCenters, handleCenterChange, selectedAreaData }) => {
//   return (
//     <Paper
//       component="form"
//       style={{ backgroundColor: 'rgb(1,1,1)', alignItems: 'center', width: '13vw', height: '5.5vh', border: 'solid 0.1vh #474747', zIndex: 999 }}
//     >
//       <CustomSelect
//         value={selectedCenters}
//         onChange={handleCenterChange}
//         label={translate('selected_center')}
//         id="region-select"
//         selectedAreaData={selectedAreaData}
//       />
//     </Paper>
//   );
// };

// export default CenterSelector;

import React, { useState, useRef, useEffect } from 'react';
import { FormControl, InputLabel, MenuItem, Paper, InputBase, Divider, IconButton, Grid, Checkbox, ListItemText } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Enviro from '../../../assets/img/APP enviro.png'
import Nanoox from '../../../assets/img/APP-nanoox-1 (003).png'
import Scapp from '../../../assets/img/APP-scapp.png'
import Pss from '../../../assets/img/APP pss.png'
import { styled } from '@mui/material/styles';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '0.3vh',
  minWidth: '0.7vw',
  maxWidth: '0.7vw',
  height: '1.3vh',
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#e84e0e',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    minWidth: '0.7vw',
    maxWidth: '0.7vw',
    height: '1.3vh',
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#e84e0e',
  },
});


const severityMap = {
  "enviro": Enviro,
  "nanoox": Nanoox,
  "scapp" : Scapp,
  "pss"   : Pss
};



const CustomSelect = ({ label, value, onChange, selectedAreaData }) => {
  // console.log('selectedAreaData', selectedAreaData)
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCheckboxChange = (centerName, service) => {
    const newValue = { ...value };
    if (!newValue[centerName]) {
      newValue[centerName] = { enviro: false, nanoox: false, scapp: false, pss: false };
    }
    newValue[centerName][service] = !newValue[centerName][service];
    onChange(newValue);
  };

  return (
    <FormControl fullWidth>
      <Grid container justifyContent='center'>
        <InputLabel style={{ fontSize: '1.8vh' }}>{label}</InputLabel>
        <InputBase
          fullWidth
          value={Object.keys(value).join(', ')} // Mostrar valores seleccionados
          onClick={handleOpen}
          inputProps={{ readOnly: true }}
          style={{ marginTop: '1vh', marginLeft: '-1.5vw', fontSize: '1.8vh', zIndex: 1, width: '10vw', overflow: 'auto' }}
        />
        <Divider sx={{ height: '3.5vh', marginLeft: '9vw', marginTop: '-3.7vh' }} orientation="vertical" />
        <IconButton sx={{ marginTop: '-3.5vh', marginLeft: '10.9vw' }} onClick={open ? handleClose : handleOpen}>
          {open ? <KeyboardArrowUpIcon style={{ fontSize: '2vh' }} /> : <KeyboardArrowDownIcon style={{ fontSize: '2vh' }} />}
        </IconButton>
      </Grid>
      {open && (
        <Paper ref={wrapperRef} style={{ width:'20vw',maxHeight:'43vh',overflowY:'auto'}}>
          {selectedAreaData && selectedAreaData.centers &&
            selectedAreaData.centers.map((centro, index) => (
              <MenuItem key={centro.name} style={{ height:'6vh', backgroundColor: index % 2 === 0 ? '#1e1e1e' : '#303030' }}>
                <Grid container justifyContent='center' alignContent='center' style={{height:'5vh'}}>
                  <Grid container justifyContent='center' alignContent='center' height='2vh' >
                    <p style={{ fontSize: '1vw', textAlign:'center' }} >
                      {centro.name}
                    </p>
                  </Grid>
                  <Grid container justifyContent='center' alignContent='center' height='3vh' >
                    {['enviro', 'nanoox', 'scapp', 'pss'].map((service) => (
                      <Grid container justifyContent='center' alignContent='center' alignItems='center' display={'flex'} item key={service} xs={3} >
                        <Grid container justifyContent='center' item xs={2}>
                          <Checkbox
                            checked={value[centro.name] ? value[centro.name][service] : false}
                            onChange={() => handleCheckboxChange(centro.name, service)}
                            sx={{
                              '&:hover': { bgcolor: 'transparent' },
                            }}
                            color="default"
                            checkedIcon={<BpCheckedIcon />}
                            icon={<BpIcon />}
                            inputProps={{ 'aria-label': 'Checkbox demo' }}
                          />
                        </Grid>
                        
                        <Grid container justifyContent='center'  item xs={8}>
                          <img src={severityMap[service]} style={{ height:'2.5vh', aspectRatio:1}}/>
                        </Grid>

                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </MenuItem>
            ))}
        </Paper>
      )}
    </FormControl>
  );
};

const CenterSelector = ({ translate, selectedCenters, handleCenterChange, selectedAreaData }) => {
  return (
    <Paper
      component="form"
      style={{ backgroundColor: 'rgb(1,1,1)', alignItems: 'center', width: '13vw', height: '5.5vh', border: 'solid 0.1vh #474747', zIndex: 999 }}
    >
      <CustomSelect
        value={selectedCenters}
        onChange={handleCenterChange}
        label={translate('selected_center')}
        id="region-select"
        selectedAreaData={selectedAreaData}
      />
    </Paper>
  );
};

export default CenterSelector;
