import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Scrollbar, Mousewheel, EffectCoverflow, Pagination , Navigation } from 'swiper/modules';
import { Grid,  Paper, IconButton, Typography, Divider, Button, Chip, Tooltip  } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import AreaSelector from '../SeleccionAcceso/Area';
import CenterSelector from '../SeleccionAcceso/Centro';
import './utils/scroll.css'

const CardReggions = ({
    user,
    remove,
    translate, 
    capitalize,
    handleCenterDelete}) => {
    const [expandedRegion, setExpandedRegion] = useState(null);
    const [hoveredArea, setHoveredArea] = useState(null);

    // const handleExpand = (regionIndex) => {
    //     setExpandedRegion(expandedRegion === regionIndex ? null : regionIndex);
    // };

    const handleMouseEnter = (regionIndex, areaIndex) => {
        setHoveredArea({ regionIndex, areaIndex });
    };

    const handleMouseLeave = () => {
        setHoveredArea(null);
    };
  return (
    <Grid container justifyContent="center" item xs={12} style={{ marginTop: "3vh", zIndex:0 }}>
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        // slidesPerView={'auto'}
        slidesPerView={3} 
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={true}
        modules={[EffectCoverflow, Pagination]}
        className="mySwiper"
      >
        {user && user.permisos && user.permisos.map((regionData, regionIndex) => {
          // const isExpanded = expandedRegion === regionIndex;
          return (
            <SwiperSlide key={regionIndex}>
              <Paper
                elevation={3}
                style={{
                    // border: 'solid',
                  // width: isExpanded ? '15vw' : '5vw',
                  // height: isExpanded ? '25vh' : '10vh',
                  width: '30vw' ,
                  height: '45vh' ,
                  borderRadius: '2vh' ,
                  cursor: 'pointer',
                  overflow: 'hidden',
                  transition: 'all 0.3s ease',
                  marginRight:'1vw',
                  backgroundColor:'#282828'
                }}
                
              >
                <Grid container direction="center" alignItems="center" style={{  height: '5vh',  }}>
                  <Grid container justifyContent="center" alignItems="center" item xs={10} style={{ height: '4vh', width: '100%' }}>
                    <Typography  style={{ fontSize: '2.5vh' , marginTop: '-0.1vh', marginLeft: '3vw', textAlign: 'center' }}>
                      {capitalize(String(regionData.REGION))}
                    </Typography >
                      
                  </Grid>
                  <Grid container justifyContent="center" alignItems="center" item xs={2} >
                    <IconButton
                      onClick={(e) => { e.stopPropagation(); remove(regionIndex); }}
                      variant="outlined"
                      size="small"
                    >
                      <DeleteIcon style={{ fontSize: '2vh' }} />
                    </IconButton>
                  </Grid>
                </Grid>
                <Swiper
                  watchSlidesProgress={true} 
                  direction={'vertical'}
                  scrollbar={{ draggable: true }}
                  mousewheel={true}
                  slidesPerView={2} // cantidad de ususraios visibles
                  modules={[Pagination, Navigation, FreeMode, Scrollbar, Mousewheel]}
                  // className="mySwiper"
                  style={{ maxHeight:'40vh'}}
                >
                  {regionData.AREAS.map((areaData, areaIndex) => {
                    const colorArea = areaIndex % 2 === 0 ? '#0f0f0f' : '#212121';
                    // const colorArea =  '#0f0f0f' // Tamaño personalizado 363636 boton:6a6a6a
                    // console.log('areaData.CENTRO',areaData.CENTRO)
                    return (
                      <SwiperSlide key={areaIndex} >
                        <Grid style={{ backgroundColor: colorArea, height:'20vh'}} container justifyContent='center' key={areaIndex}>
                          <Typography variant="subtitle1" style={{fontSize:'2vh'}}>
                            {/* {translate('area')}:  */}
                            {capitalize(String(areaData.AREA))}
                          </Typography>
                            <Grid container justifyContent='center' alignContent='center'marginTop='-2.5vh' >
                             
                                  {Object.keys(areaData.CENTRO).map((centerItem, centerIndex) => {
                                    return(
                                      <Grid item xs={5} marginTop={'1vh'} key={centerIndex}>
                                        <Grid container justifyContent='center' item xs={11} style={{borderRadius:'2vh', backgroundColor:'#363636', fontSize:'2vh'}}>
                                          {centerItem}
                                          <IconButton onClick={handleCenterDelete(regionIndex, areaIndex, centerItem)} sx={{ position: 'absolute', marginTop: '-0.5vh', marginLeft: '6vw', }} >
                                            <DeleteIcon style={{fontSize:'2.2vh'}} />
                                          </IconButton>
                                        </Grid>
                                      </Grid> 
                                    )
                                  })}
                            </Grid>
                          <Button
                            onClick={(e) => { e.stopPropagation(); remove(regionIndex, areaIndex); }}
                            variant="outlined"
                            color="inherit"
                            size="small"
                            style={{fontSize:'1.4vh', marginTop:'1.5vh', marginBottom:'1vh', height:'4vh'}}
                          >
                            {translate('borrar_area')}
                          </Button>
                        </Grid>
                      </SwiperSlide>
                    )
                  })}
                </Swiper>
              </Paper>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Grid>
  )
}

export default CardReggions