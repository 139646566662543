// import React from 'react';
// import FormControl from '@mui/material/FormControl';
// import InputLabel from '@mui/material/InputLabel';
// import Select from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';

// const RegionSelector = ({ translate, region, handleRegionChange, datosArray }) => {

//     return (
//       <FormControl fullWidth>
//         <InputLabel htmlFor="region-select">{translate('selected_region')}</InputLabel>
//         <Select
//           value={region}
//           onChange={handleRegionChange}
//           label={translate('selected_region')}
//           id="region-select"
//         >
//           <MenuItem value="">{translate('selected_region')}</MenuItem>
//               {datosArray.map((region) => (
//                 <MenuItem key={region.REGION} value={region.name}>
//                   {region.name}
//                 </MenuItem>
//               ))}
//         </Select>
//       </FormControl>
//     );
// }
// export default RegionSelector

import React,{useState, useEffect, useRef} from 'react';
import { FormControl, InputLabel, MenuItem, Paper, InputBase, Divider, IconButton, Grid } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const CustomSelect = ({ label, value, onChange, datosArray, capitalize }) => {
  const [open, setOpen] = useState(false);

  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMenuItemClick = (regionName) => {
    // console.log('region', regionName);
    onChange(regionName); // Pasar el nombre de la región a onChange
    handleClose();
  };

  return (
    <FormControl fullWidth>
      <Grid container justifyContent='center' >
        <InputLabel style={{fontSize:'1.8vh'}}>{capitalize(String(label))}</InputLabel>
        <InputBase
          fullWidth
          value={value}
          onClick={handleOpen}
          inputProps={{ readOnly: true }}
          style={{ marginTop:'1vh', marginLeft:'0.5vw', fontSize:'1.8vh',zIndex:1}}
        />
        <Divider sx={{ height: '3.5vh',marginLeft:'9vw', marginTop:'-3.7vh' }} orientation="vertical" />
         <IconButton  sx={{marginTop:'-3.5vh',marginLeft:'10.9vw'}} >
            {open ? <KeyboardArrowUpIcon style={{fontSize:'2vh'}} /> :<KeyboardArrowDownIcon style={{fontSize:'2vh'}} /> }
          </IconButton>
      </Grid>
      {open && (
        <Paper ref={wrapperRef}>
          {datosArray.map((region) => (
            <MenuItem style={{fontSize:'1.8vh'}} key={region.name} onClick={()=>handleMenuItemClick(region.name)} value={region.name}>
              {capitalize(String(region.name))}
            </MenuItem>
          ))}
        </Paper>
      )}
    </FormControl>
  );
};

const RegionSelector = ({ translate, region, handleRegionChange, datosArray, capitalize,dataFlase }) => {

    return (
      <Paper 
      component="form"
                style={{ backgroundColor:'rgb(1,1,1)',alignItems: 'center', width: '13vw', height:'5.5vh', border:'solid 0.1vh #474747', zIndex:33 }}>
                  
        <CustomSelect
          value={capitalize(String(region))}
          onChange={handleRegionChange}
          label={translate('selected_region')}
          id="region-select"
          datosArray={datosArray}
          capitalize={capitalize}
        />
      </Paper>
    );
}
export default RegionSelector