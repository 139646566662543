import React, {useEffect, useState} from 'react';
import { Grid, Paper, Modal, Button, Typography  } from '@mui/material';
import CentersPagination from '../components/CenterPagination';
import ControlManagerAppbar from '../components/AppBar/ControlManagerAppbar';
import Construccion from '../assets/img/construccion.png'
import '../assets/css/Construccion.css'
import './utils/css/style.css'

import { GetCenters, fetchConsolidadoService, fetchConsolidadoCenters } from '../utils/apiServer';

const style = {
  container: {
    border: 'solid 0.1vh red',
    borderRadius:'3vh',
    // borderTopRightRadius:'3vh',
    // marginRigth: '1vw',
    marginTop: '1vh',
    textAlign: 'center',
    width: '6vw',
    height:'9vh'
  },
  img: {
    // minHeight: '7vh',
    height: '9vh',
    borderRadius:'50vh',
    // aspectRatio:16,
    // width:'3vw',
    marginLeft: '0.5vh',
    selected: {
      backgroundColor: 'rgb(255,255,255,0.1)', // Cambia el color de fondo para indicar la selección
    },
    // marginBottom: '5vh',
  },
  content: {
    // marginLeft: '1vw',
    border: 'solid 0.1vh ',
    width: '8vw',
    height: '5vh',
    textAlign: 'center',
    paddingTop: '0.2vh',
    fontSize: '3vh',
    borderBottomLeftRadius:'3vh',
    borderBottomRightRadius:'3vh',
    selected: {
      backgroundColor: 'rgb(255,255,255,0.1)', // Cambia el color de fondo para indicar la selección
    },
    img1: {
      height: '3vh',
    },
    img: {
      height: '2vh',
      marginTop: '-0.8vh',
    },
    imgScapp: {
      height: '2vh',
      marginTop: '0vh',
    },
    imgEnviro: {
      height: '3vh',
      marginTop: '-1.8vh',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'default !important', userSelect: 'none',
  },
  modalPaper: {
    // border: '0.01vh solid ',
    borderRadius:'2vh',
    textAlign: 'center',
    width: '25vw',
    height: '32vh',
    img:{
        height: '10vh',
        marginTop:'5vh'
    },
    content:{
        fontSize:'2.5vh',
        // marginTop:'2vh',
        // marginBottom:'3vh'
    },
    button:{
        fontSize:'2vh',
    }
  },
};

const severityMap = {
  "C": "rgb(211, 47, 47)",
  "A": "rgb(253, 183, 70)",
  "B": "rgb(0, 128, 0)"
};

const servicios = {
  'Enviro': true,
  'Nanoox': true,
  'Scapp': false,
  'Pss': false,
};


function capitalize(value) {
  if (!value) return '';

  // Divide la cadena en palabras
  let words = value.toLowerCase().split(' ');

  // Capitaliza la primera letra de cada palabra y divide 'Los' y 'Puerto' si están dentro de una palabra más grande
  let capitalizedWords = words.map(word => {
    // Reemplaza 'Los' y 'Puerto' en cualquier posición dentro de una palabra
    word = word.replace(/los/gi, 'Los ').replace(/puerto/gi, 'Puerto ');

    // Capitaliza la primera letra de cada palabra resultante
    return word.split(' ').map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');
  });

  // Une las palabras nuevamente en una cadena
  let capitalized = capitalizedWords.join(' ');

  return capitalized.trim(); // Remover cualquier espacio adicional accidental
}


const Home = ({SWVersion, registro, translate, handleClick, openMenuIdioma ,handleCloseIdioma, handleLanguageChange, cargo, selectedArea, setSelectedArea, selectedRegion, setSelectedRegion}) => {
  
  const [consolidado_estados, setConsolidado_estados] = useState({});
  const [selectedService, setSelectedService] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [open, setOpen] = useState(true); //drawer

   //* ----------------------------- Estado del Componente para la Selección de Regiones, Áreas y Centros -----------------------------
    //? Descripción:
    // Se definen estados para manejar la selección de región, área y centro.
    // - selectedRegion: Almacena la región actualmente seleccionada.
    // - selectedArea: Almacena el área actualmente seleccionada dentro de la región.
    // - selectedCentro: Almacena el centro actualmente seleccionado dentro del área.
    // const [selectedRegion, setSelectedRegion] = useState(null);
    // const [selectedArea, setSelectedArea] = useState(null);
    const [selectedCentro, setSelectedCentro] = useState(null);


  const handleCloseModal = () => {
    setModalOpen(false);
  };


  const handleServiceClick = async (service) => {

    if (servicios[capitalize(service?.name)]) {
      setSelectedService(service);
      console.log('servicio', service)
  
      const [success, centers] = await GetCenters({ service });
      console.log('centers', centers);
  
      if (service.status_service === false ) { // Verificar si centers es null o está vacío
        setModalMessage(translate('service_not_available_message').replace('{value}', service?.name));
        setModalOpen(true);
      // if (!centers || Object.keys(centers).length === 0) { // Verificar si centers es null o está vacío
      } else if (!centers || Object.keys(centers).length === 0) {
         // setModalMessage(translate('service_not_available_message').replace('{value}', service.name));
         setModalMessage(translate('centers_retrieval_error_message').replace('{value}', service?.name));
         setModalOpen(true);
      } else {
       
        console.log(`holaaaaaa /${capitalize(service?.name)}`);
        localStorage.setItem('Regiones', JSON.stringify(centers));
        window.location.href = `/${capitalize(service?.name)}`;
      }
    } else {
      setModalMessage(translate('service_inactive_message').replace('{value}', service?.name));
      setModalOpen(true);
    }
  };
  

  const [consolidado_service, setConsolidado_service] = useState({});


  useEffect(() => {
    const fetchData = async () => {
        const result = await fetchConsolidadoService();
        setConsolidado_service(result);
        // //console.log('response: setConsolidado_service', result)

    };

    // Fetch data initially
    fetchData();

    // Set interval to fetch data every 60 seconds
    const interval = setInterval(fetchData, 60000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
}, [selectedArea]);


function transformConsolidadoService(consolidado_service) {
  const result = {
      name: '',
      statusRegions: '',
      areas: []
  };

  // Asumimos que solo hay una clave en consolidado_service (por ejemplo, "Loslagos-Puertomontt")
  const regionKey = Object.keys(consolidado_service)[0];
  const regionParts = regionKey?.split('-');
  result.name = regionParts?.[0]; // "Loslagos"
  result.statusRegions = getStatusRegion(consolidado_service[regionKey]);

  // Iterar sobre las áreas dentro de la región
  for (const areaKey in consolidado_service[regionKey]) {
      const areaParts = areaKey.split('-');
      const area = {
          name: areaParts[1], // "Puertomontt"
          statusArea: getStatusArea(consolidado_service[regionKey][areaKey]),
          centers: []
      };

      // Iterar sobre los centros dentro del área
      for (const centerKey in consolidado_service[regionKey][areaKey]) {
          const center = {
              name: centerKey, // "Itg"
              ...consolidado_service[regionKey][areaKey][centerKey]
          };

          area.centers.push(center);
      }

      result.areas.push(area);
  }

  return result;
}

function getStatusRegion(region) {
  for (const areaKey in region) {
      for (const centerKey in region[areaKey]) {
          return region[areaKey][centerKey].status;
      }
  }
  return '';
}

// Función para obtener el estado del área (puedes ajustar la lógica según tu necesidad)
function getStatusArea(area) {
  for (const centerKey in area) {
      return area[centerKey].status;
  }
  return '';
}
  useEffect(() => {
    const fetchData = async () => {
        const PAGE ='HOME' 
        const result = await fetchConsolidadoCenters(PAGE);
        setConsolidado_estados(result);
        // console.log('response: consolidado_estados', result)

    };

    // Fetch data initially
    fetchData();

    // Set interval to fetch data every 60 seconds
    const interval = setInterval(fetchData, 60000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
}, []);
// *-------------------------------------------

const ControlManagerAppbarProps = {
  open:open,
  setOpen:setOpen,
  SWVersion: SWVersion,
  consolidado_estados: consolidado_estados,
  severityMap:severityMap,
  selectedRegion:selectedRegion,
  setSelectedRegion:setSelectedRegion,
  selectedArea:selectedArea,
  setSelectedArea:setSelectedArea,
  translate:translate,
  cargo:cargo,
  registro:registro,
  handleClick:handleClick,
  openMenuIdioma:openMenuIdioma,
  handleCloseIdioma:handleCloseIdioma,
  handleLanguageChange:handleLanguageChange,
  capitalize:capitalize
} 

const CentersPaginationProps = {
  open:open,
  selectedRegion:selectedRegion,
  selectedArea: selectedArea, 
  consolidado_service: consolidado_service, 
  transformConsolidadoService: transformConsolidadoService, 
  handleServiceClick: handleServiceClick, 
  selectedService: selectedService,
  capitalize:capitalize,
  severityMap:severityMap,
  translate:translate,

  // filteredCenters:filteredCenters
} 

  return (
    <Grid container  justifyContent='center' style={{ cursor: 'default !important', userSelect: 'none', }}>
      <Grid container  justifyContent='center' style={{marginTop:'-1vh', marginBottom:'1vh'}}>
        <ControlManagerAppbar {...ControlManagerAppbarProps}/>

      </Grid>
      <Grid container justifyContent='center' alignContent='center' style={{width: open ? '83vw' : '98vw' , height:'85vh', marginLeft: open ? '16vw': '0vw', marginTop:'4vh' }}>
        <Grid container justifyContent={'center'} alignContent='center' style={{ height:'6vh', marginTop:'-3vh'}}>
          <p style={{fontSize:'5vh'}}> {capitalize(selectedRegion?.name)}: {capitalize(selectedArea)} </p>
        </Grid>
        <Grid container justifyContent='center'>
          {/* <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                <Button variant="contained" onClick={() => setFilter('ALL')}>Mostrar Todos</Button>
                <Button variant="contained" onClick={() => setFilter('GOOD')} style={{ marginLeft: '10px' }}>Centros Buenos (A, C)</Button>
                <Button variant="contained" onClick={() => setFilter('BAD')} style={{ marginLeft: '10px' }}>Centros con Fallas (B)</Button>
            </div> */}
        </Grid>

        <Grid container justifyContent='center' alignContent='center' style={{ height:'82vh'}} >
          <CentersPagination {...CentersPaginationProps}/>
        </Grid>
      </Grid>
      <Modal open={modalOpen} onClose={handleCloseModal} style={{ ...style.modal }}>
        <Paper style={{ ...style.modalPaper }}>
          <img src={Construccion} style={{ ...style.modalPaper.img }} className="App-logo" alt="logo" />
          <Typography style={{ ...style.modalPaper.content }}>{modalMessage} ...</Typography>
          <Button style={{ ...style.modalPaper.button }} onClick={handleCloseModal} color="primary">
            {translate('accept_button')}
          </Button>
        </Paper>
      </Modal>
    </Grid>
  );
};

export default Home;
